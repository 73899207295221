import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'
const { v4: uuidv4 } = require('uuid')
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

// App components
import Layout from '../../components/layout'
import MainHelmet from '../../components/MainHelmet'
import { styles as titleStyles } from '../../components/PageTitle'

// App utils
import { themeStyles, presets } from '../../utils/theme'

const styles = {
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    width: '100%',
    ...themeStyles.textPadding,
    paddingTop: 0,
    justifyContent: 'center',
    [presets.Mobile]: {
      paddingTop: 0,
    },
  },
  image: {
    flex: 'auto',
    height: '270px',
    minWidth: '150px',
    maxWidth: '250px',
    margin: '0 8px 8px 0',
    contentFit: 'contain',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
}

const reqImages = require.context(
  '../../assets/see-our-work',
  true,
  /\.(png|PNG|jpe?g|svg)$/
)
const paths = reqImages.keys().sort()

const images = []
const thumbnails = []

paths.forEach((path) => {
  const image = reqImages(path)
  if (/thumbnails/.test(path)) {
    thumbnails.push(image)
  } else {
    images.push(image.default)
  }
})



class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  onClickPhoto = (photoIndex) => {
    console.log(photoIndex)
    this.setState({ isOpen: true, photoIndex })
  }

  render() {
    const imageElements = []
    let count = 0
    _.forEach(this.props.data, (item) => {
      item.childImageSharp.gatsbyImageData.id = uuidv4()

      const index = imageElements.length

      var image = item.childImageSharp.gatsbyImageData

      const backgroundCss = {
        backgroundImage: `url(${image.images.fallback.src})`,
      }
      imageElements.push(
        <div
          key={index}
          async={count > 50}
          onClick={() => this.onClickPhoto(index)}
          css={[styles.image, backgroundCss]}
        >
          <GatsbyImage
            image={image}
            css={[styles.image, { opacity: 0 }]}
            alt="see our work"
            key={image.id}
          />
        </div>
      )
    })


    const { photoIndex, isOpen } = this.state

    return (
      <Layout location={this.props.location}>
        <div css={[themeStyles.contentWidth, { position: 'relative' }]}>
          <MainHelmet
            description={`Collins Orthodontics, Rochester MN | See Our Work`}
            title="See Our Work"
          />
          <h1 css={[titleStyles.title, { top: 0, bottom: 'auto' }]}>
            {'See Our Work'.toUpperCase()}
          </h1>
          <div css={styles.imageContainer}>{imageElements}</div>
          {isOpen && (
            <Lightbox
              enableZoom={false}
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </div>
      </Layout>
    )
  }
}

const SeeOurWorkWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query SeeOurWork {
        a001: file(relativePath: { eq: "see-our-work/001.jpg" }) {
          ...fixedImage270Height
        }
        a002: file(relativePath: { eq: "see-our-work/002.jpg" }) {
          ...fixedImage270Height
        }
        a003: file(relativePath: { eq: "see-our-work/003.jpg" }) {
          ...fixedImage270Height
        }
        a004: file(relativePath: { eq: "see-our-work/004.jpg" }) {
          ...fixedImage270Height
        }
        a005: file(relativePath: { eq: "see-our-work/005.jpg" }) {
          ...fixedImage270Height
        }
        a006: file(relativePath: { eq: "see-our-work/006.jpg" }) {
          ...fixedImage270Height
        }
        a007: file(relativePath: { eq: "see-our-work/007.jpg" }) {
          ...fixedImage270Height
        }
        a008: file(relativePath: { eq: "see-our-work/008.jpg" }) {
          ...fixedImage270Height
        }
        a009: file(relativePath: { eq: "see-our-work/009.jpg" }) {
          ...fixedImage270Height
        }
        a010: file(relativePath: { eq: "see-our-work/010.jpg" }) {
          ...fixedImage270Height
        }
        a010a: file(relativePath: { eq: "see-our-work/010a.jpg" }) {
          ...fixedImage270Height
        }
        a011: file(relativePath: { eq: "see-our-work/011.jpg" }) {
          ...fixedImage270Height
        }
        a012: file(relativePath: { eq: "see-our-work/012.jpg" }) {
          ...fixedImage270Height
        }
        a013: file(relativePath: { eq: "see-our-work/013.jpg" }) {
          ...fixedImage270Height
        }
        a013a: file(relativePath: { eq: "see-our-work/013a.jpg" }) {
          ...fixedImage270Height
        }
        a014: file(relativePath: { eq: "see-our-work/014.jpg" }) {
          ...fixedImage270Height
        }
        a015: file(relativePath: { eq: "see-our-work/015.jpg" }) {
          ...fixedImage270Height
        }
        a016: file(relativePath: { eq: "see-our-work/016.jpg" }) {
          ...fixedImage270Height
        }
        a017: file(relativePath: { eq: "see-our-work/017.jpg" }) {
          ...fixedImage270Height
        }
        a018: file(relativePath: { eq: "see-our-work/018.jpg" }) {
          ...fixedImage270Height
        }
        a019: file(relativePath: { eq: "see-our-work/019.jpg" }) {
          ...fixedImage270Height
        }
        a024: file(relativePath: { eq: "see-our-work/024.jpg" }) {
          ...fixedImage270Height
        }
        a025: file(relativePath: { eq: "see-our-work/025.jpg" }) {
          ...fixedImage270Height
        }
        a026: file(relativePath: { eq: "see-our-work/026.png" }) {
          ...fixedImage270Height
        }
        a027: file(relativePath: { eq: "see-our-work/027.png" }) {
          ...fixedImage270Height
        }
        a028: file(relativePath: { eq: "see-our-work/028.png" }) {
          ...fixedImage270Height
        }
        a029: file(relativePath: { eq: "see-our-work/029.png" }) {
          ...fixedImage270Height
        }
        a030: file(relativePath: { eq: "see-our-work/030.jpg" }) {
          ...fixedImage270Height
        }
        a031: file(relativePath: { eq: "see-our-work/031.jpg" }) {
          ...fixedImage270Height
        }
        a032: file(relativePath: { eq: "see-our-work/032.png" }) {
          ...fixedImage270Height
        }
        a032a: file(relativePath: { eq: "see-our-work/032a.jpg" }) {
          ...fixedImage270Height
        }
        a033: file(relativePath: { eq: "see-our-work/033.png" }) {
          ...fixedImage270Height
        }
        a034: file(relativePath: { eq: "see-our-work/034.png" }) {
          ...fixedImage270Height
        }
        a035: file(relativePath: { eq: "see-our-work/035.png" }) {
          ...fixedImage270Height
        }
        a036: file(relativePath: { eq: "see-our-work/036.png" }) {
          ...fixedImage270Height
        }
        a037: file(relativePath: { eq: "see-our-work/037.png" }) {
          ...fixedImage270Height
        }
        a038: file(relativePath: { eq: "see-our-work/038.png" }) {
          ...fixedImage270Height
        }
        a039: file(relativePath: { eq: "see-our-work/039.png" }) {
          ...fixedImage270Height
        }
        a040: file(relativePath: { eq: "see-our-work/040.jpg" }) {
          ...fixedImage270Height
        }
        a041: file(relativePath: { eq: "see-our-work/041.png" }) {
          ...fixedImage270Height
        }
        a042: file(relativePath: { eq: "see-our-work/042.png" }) {
          ...fixedImage270Height
        }
        a043: file(relativePath: { eq: "see-our-work/043.png" }) {
          ...fixedImage270Height
        }
        a044: file(relativePath: { eq: "see-our-work/044.jpg" }) {
          ...fixedImage270Height
        }
        a045: file(relativePath: { eq: "see-our-work/045.jpg" }) {
          ...fixedImage270Height
        }
        a046: file(relativePath: { eq: "see-our-work/046.jpg" }) {
          ...fixedImage270Height
        }
        a047: file(relativePath: { eq: "see-our-work/047.png" }) {
          ...fixedImage270Height
        }
        a048: file(relativePath: { eq: "see-our-work/048.jpg" }) {
          ...fixedImage270Height
        }
        a049: file(relativePath: { eq: "see-our-work/049.jpg" }) {
          ...fixedImage270Height
        }
        a050: file(relativePath: { eq: "see-our-work/050.jpg" }) {
          ...fixedImage270Height
        }
        a051: file(relativePath: { eq: "see-our-work/051.jpg" }) {
          ...fixedImage270Height
        }
        a052: file(relativePath: { eq: "see-our-work/052.jpg" }) {
          ...fixedImage270Height
        }
        a053: file(relativePath: { eq: "see-our-work/053.jpg" }) {
          ...fixedImage270Height
        }
        a054: file(relativePath: { eq: "see-our-work/054.jpg" }) {
          ...fixedImage270Height
        }
        a055: file(relativePath: { eq: "see-our-work/055.jpg" }) {
          ...fixedImage270Height
        }
        a056: file(relativePath: { eq: "see-our-work/056.jpg" }) {
          ...fixedImage270Height
        }
        a057: file(relativePath: { eq: "see-our-work/057.jpg" }) {
          ...fixedImage270Height
        }
        a058: file(relativePath: { eq: "see-our-work/058.jpg" }) {
          ...fixedImage270Height
        }
        a059: file(relativePath: { eq: "see-our-work/059.jpg" }) {
          ...fixedImage270Height
        }
        a060: file(relativePath: { eq: "see-our-work/060.jpg" }) {
          ...fixedImage270Height
        }
        a061: file(relativePath: { eq: "see-our-work/061.jpg" }) {
          ...fixedImage270Height
        }
        a062: file(relativePath: { eq: "see-our-work/062.jpg" }) {
          ...fixedImage270Height
        }
        a063: file(relativePath: { eq: "see-our-work/063.jpg" }) {
          ...fixedImage270Height
        }
        a064: file(relativePath: { eq: "see-our-work/064.jpg" }) {
          ...fixedImage270Height
        }
        a065: file(relativePath: { eq: "see-our-work/065.jpg" }) {
          ...fixedImage270Height
        }
        a066: file(relativePath: { eq: "see-our-work/066.jpg" }) {
          ...fixedImage270Height
        }
        a067: file(relativePath: { eq: "see-our-work/067.jpg" }) {
          ...fixedImage270Height
        }
        a068: file(relativePath: { eq: "see-our-work/068.jpg" }) {
          ...fixedImage270Height
        }
        a069: file(relativePath: { eq: "see-our-work/069.jpg" }) {
          ...fixedImage270Height
        }
        a070: file(relativePath: { eq: "see-our-work/070.jpg" }) {
          ...fixedImage270Height
        }
        a071: file(relativePath: { eq: "see-our-work/071.jpg" }) {
          ...fixedImage270Height
        }
        a072: file(relativePath: { eq: "see-our-work/072.jpg" }) {
          ...fixedImage270Height
        }
        a073: file(relativePath: { eq: "see-our-work/073.jpg" }) {
          ...fixedImage270Height
        }
        a074: file(relativePath: { eq: "see-our-work/074.jpg" }) {
          ...fixedImage270Height
        }
        a075: file(relativePath: { eq: "see-our-work/075.jpg" }) {
          ...fixedImage270Height
        }
        a076: file(relativePath: { eq: "see-our-work/076.jpg" }) {
          ...fixedImage270Height
        }
        a077: file(relativePath: { eq: "see-our-work/077.jpg" }) {
          ...fixedImage270Height
        }
        a078: file(relativePath: { eq: "see-our-work/078.jpg" }) {
          ...fixedImage270Height
        }
        a079: file(relativePath: { eq: "see-our-work/079.jpg" }) {
          ...fixedImage270Height
        }
        a080: file(relativePath: { eq: "see-our-work/080.jpg" }) {
          ...fixedImage270Height
        }
        a081: file(relativePath: { eq: "see-our-work/081.jpg" }) {
          ...fixedImage270Height
        }
        a082: file(relativePath: { eq: "see-our-work/082.jpg" }) {
          ...fixedImage270Height
        }
        a083: file(relativePath: { eq: "see-our-work/083.jpg" }) {
          ...fixedImage270Height
        }
        a084: file(relativePath: { eq: "see-our-work/084.jpg" }) {
          ...fixedImage270Height
        }
        a085: file(relativePath: { eq: "see-our-work/085.jpg" }) {
          ...fixedImage270Height
        }
        a086: file(relativePath: { eq: "see-our-work/086.jpg" }) {
          ...fixedImage270Height
        }
        a087: file(relativePath: { eq: "see-our-work/087.jpg" }) {
          ...fixedImage270Height
        }
        a088: file(relativePath: { eq: "see-our-work/088.jpg" }) {
          ...fixedImage270Height
        }
        a089: file(relativePath: { eq: "see-our-work/089.jpg" }) {
          ...fixedImage270Height
        }
        a090: file(relativePath: { eq: "see-our-work/090.jpg" }) {
          ...fixedImage270Height
        }
        a091: file(relativePath: { eq: "see-our-work/091.jpg" }) {
          ...fixedImage270Height
        }
        a092: file(relativePath: { eq: "see-our-work/092.jpg" }) {
          ...fixedImage270Height
        }
        a093: file(relativePath: { eq: "see-our-work/093.jpg" }) {
          ...fixedImage270Height
        }
        a094: file(relativePath: { eq: "see-our-work/094.jpg" }) {
          ...fixedImage270Height
        }
        a095: file(relativePath: { eq: "see-our-work/095.jpg" }) {
          ...fixedImage270Height
        }
        a096: file(relativePath: { eq: "see-our-work/096.jpg" }) {
          ...fixedImage270Height
        }
        a097: file(relativePath: { eq: "see-our-work/097.jpg" }) {
          ...fixedImage270Height
        }
        a098: file(relativePath: { eq: "see-our-work/098.jpg" }) {
          ...fixedImage270Height
        }
        a099: file(relativePath: { eq: "see-our-work/099.jpg" }) {
          ...fixedImage270Height
        }
        a100: file(relativePath: { eq: "see-our-work/100.jpg" }) {
          ...fixedImage270Height
        }
        a101: file(relativePath: { eq: "see-our-work/101.jpg" }) {
          ...fixedImage270Height
        }
        a102: file(relativePath: { eq: "see-our-work/102.jpg" }) {
          ...fixedImage270Height
        }
        a103: file(relativePath: { eq: "see-our-work/103.jpg" }) {
          ...fixedImage270Height
        }
        a104: file(relativePath: { eq: "see-our-work/104.jpg" }) {
          ...fixedImage270Height
        }
        a105: file(relativePath: { eq: "see-our-work/105.jpg" }) {
          ...fixedImage270Height
        }
        a106: file(relativePath: { eq: "see-our-work/106.jpg" }) {
          ...fixedImage270Height
        }
        a107: file(relativePath: { eq: "see-our-work/107.jpg" }) {
          ...fixedImage270Height
        }
        a108: file(relativePath: { eq: "see-our-work/108.jpg" }) {
          ...fixedImage270Height
        }
        a109: file(relativePath: { eq: "see-our-work/109.jpg" }) {
          ...fixedImage270Height
        }
        a110: file(relativePath: { eq: "see-our-work/110.jpg" }) {
          ...fixedImage270Height
        }
        a111: file(relativePath: { eq: "see-our-work/111.jpg" }) {
          ...fixedImage270Height
        }
        a112: file(relativePath: { eq: "see-our-work/112.jpg" }) {
          ...fixedImage270Height
        }
        a113: file(relativePath: { eq: "see-our-work/113.jpg" }) {
          ...fixedImage270Height
        }
        a114: file(relativePath: { eq: "see-our-work/114.jpg" }) {
          ...fixedImage270Height
        }
        a115: file(relativePath: { eq: "see-our-work/115.jpg" }) {
          ...fixedImage270Height
        }
        a116: file(relativePath: { eq: "see-our-work/116.jpg" }) {
          ...fixedImage270Height
        }
        a117: file(relativePath: { eq: "see-our-work/117.jpg" }) {
          ...fixedImage270Height
        }
        a118: file(relativePath: { eq: "see-our-work/118.jpg" }) {
          ...fixedImage270Height
        }
        a119: file(relativePath: { eq: "see-our-work/119.jpg" }) {
          ...fixedImage270Height
        }
        a120: file(relativePath: { eq: "see-our-work/120.jpg" }) {
          ...fixedImage270Height
        }
        a121: file(relativePath: { eq: "see-our-work/121.jpg" }) {
          ...fixedImage270Height
        }
        a122: file(relativePath: { eq: "see-our-work/122.jpg" }) {
          ...fixedImage270Height
        }
        a123: file(relativePath: { eq: "see-our-work/123.jpg" }) {
          ...fixedImage270Height
        }
        a124: file(relativePath: { eq: "see-our-work/124.jpg" }) {
          ...fixedImage270Height
        }
        a125: file(relativePath: { eq: "see-our-work/125.jpg" }) {
          ...fixedImage270Height
        }
        a126: file(relativePath: { eq: "see-our-work/126.jpg" }) {
          ...fixedImage270Height
        }
        a127: file(relativePath: { eq: "see-our-work/127.jpg" }) {
          ...fixedImage270Height
        }
        a128: file(relativePath: { eq: "see-our-work/128.jpg" }) {
          ...fixedImage270Height
        }
        a129: file(relativePath: { eq: "see-our-work/129.jpg" }) {
          ...fixedImage270Height
        }
        a130: file(relativePath: { eq: "see-our-work/130.jpg" }) {
          ...fixedImage270Height
        }
        a131: file(relativePath: { eq: "see-our-work/131.jpg" }) {
          ...fixedImage270Height
        }
        a132: file(relativePath: { eq: "see-our-work/132.jpg" }) {
          ...fixedImage270Height
        }
        a133: file(relativePath: { eq: "see-our-work/133.jpg" }) {
          ...fixedImage270Height
        }
        a134: file(relativePath: { eq: "see-our-work/134.jpg" }) {
          ...fixedImage270Height
        }
        a135: file(relativePath: { eq: "see-our-work/135.jpg" }) {
          ...fixedImage270Height
        }
        a136: file(relativePath: { eq: "see-our-work/136.jpg" }) {
          ...fixedImage270Height
        }
        a137: file(relativePath: { eq: "see-our-work/137.jpg" }) {
          ...fixedImage270Height
        }
        a138: file(relativePath: { eq: "see-our-work/138.jpg" }) {
          ...fixedImage270Height
        }
        a139: file(relativePath: { eq: "see-our-work/139.jpg" }) {
          ...fixedImage270Height
        }
        a140: file(relativePath: { eq: "see-our-work/140.jpg" }) {
          ...fixedImage270Height
        }
        a141: file(relativePath: { eq: "see-our-work/141.jpg" }) {
          ...fixedImage270Height
        }
        a142: file(relativePath: { eq: "see-our-work/142.jpg" }) {
          ...fixedImage270Height
        }
        a143: file(relativePath: { eq: "see-our-work/143.jpg" }) {
          ...fixedImage270Height
        }
        a144: file(relativePath: { eq: "see-our-work/144.jpg" }) {
          ...fixedImage270Height
        }
        a145: file(relativePath: { eq: "see-our-work/145.jpg" }) {
          ...fixedImage270Height
        }
        a146: file(relativePath: { eq: "see-our-work/146.jpg" }) {
          ...fixedImage270Height
        }
        a147: file(relativePath: { eq: "see-our-work/147.jpg" }) {
          ...fixedImage270Height
        }
        a148: file(relativePath: { eq: "see-our-work/148.jpg" }) {
          ...fixedImage270Height
        }
        a149: file(relativePath: { eq: "see-our-work/149.jpg" }) {
          ...fixedImage270Height
        }
        a150: file(relativePath: { eq: "see-our-work/150.jpg" }) {
          ...fixedImage270Height
        }
        a151: file(relativePath: { eq: "see-our-work/151.jpg" }) {
          ...fixedImage270Height
        }
        a152: file(relativePath: { eq: "see-our-work/152.jpg" }) {
          ...fixedImage270Height
        }
        a153: file(relativePath: { eq: "see-our-work/153.jpg" }) {
          ...fixedImage270Height
        }
        a154: file(relativePath: { eq: "see-our-work/154.jpg" }) {
          ...fixedImage270Height
        }
        a155: file(relativePath: { eq: "see-our-work/155.jpg" }) {
          ...fixedImage270Height
        }
        a156: file(relativePath: { eq: "see-our-work/156.jpg" }) {
          ...fixedImage270Height
        }
        a157: file(relativePath: { eq: "see-our-work/157.jpg" }) {
          ...fixedImage270Height
        }
        a158: file(relativePath: { eq: "see-our-work/158.jpg" }) {
          ...fixedImage270Height
        }
        a159: file(relativePath: { eq: "see-our-work/159.jpg" }) {
          ...fixedImage270Height
        }
        a160: file(relativePath: { eq: "see-our-work/160.jpg" }) {
          ...fixedImage270Height
        }
        a161: file(relativePath: { eq: "see-our-work/161.jpg" }) {
          ...fixedImage270Height
        }
        a162: file(relativePath: { eq: "see-our-work/162.jpg" }) {
          ...fixedImage270Height
        }
        a163: file(relativePath: { eq: "see-our-work/163.jpg" }) {
          ...fixedImage270Height
        }
        a164: file(relativePath: { eq: "see-our-work/164.jpg" }) {
          ...fixedImage270Height
        }
        a165: file(relativePath: { eq: "see-our-work/165.jpg" }) {
          ...fixedImage270Height
        }
        a166: file(relativePath: { eq: "see-our-work/166.jpg" }) {
          ...fixedImage270Height
        }
        a167: file(relativePath: { eq: "see-our-work/167.jpg" }) {
          ...fixedImage270Height
        }
        a168: file(relativePath: { eq: "see-our-work/168.jpg" }) {
          ...fixedImage270Height
        }
        a169: file(relativePath: { eq: "see-our-work/169.jpg" }) {
          ...fixedImage270Height
        }
        a170: file(relativePath: { eq: "see-our-work/170.jpg" }) {
          ...fixedImage270Height
        }
        a180: file(relativePath: { eq: "see-our-work/180.jpg" }) {
          ...fixedImage270Height
        }
        a181: file(relativePath: { eq: "see-our-work/181.jpg" }) {
          ...fixedImage270Height
        }
        a182: file(relativePath: { eq: "see-our-work/182.jpg" }) {
          ...fixedImage270Height
        }
        a183: file(relativePath: { eq: "see-our-work/183.jpg" }) {
          ...fixedImage270Height
        }
        a184: file(relativePath: { eq: "see-our-work/184.jpg" }) {
          ...fixedImage270Height
        }
        a185: file(relativePath: { eq: "see-our-work/185.jpg" }) {
          ...fixedImage270Height
        }
        a186: file(relativePath: { eq: "see-our-work/186.jpg" }) {
          ...fixedImage270Height
        }
        a187: file(relativePath: { eq: "see-our-work/187.jpg" }) {
          ...fixedImage270Height
        }
        a188: file(relativePath: { eq: "see-our-work/188.jpg" }) {
          ...fixedImage270Height
        }
        a189: file(relativePath: { eq: "see-our-work/189.jpg" }) {
          ...fixedImage270Height
        }
        a190: file(relativePath: { eq: "see-our-work/190.jpg" }) {
          ...fixedImage270Height
        }
        a191: file(relativePath: { eq: "see-our-work/191.jpg" }) {
          ...fixedImage270Height
        }
        a192: file(relativePath: { eq: "see-our-work/192.jpg" }) {
          ...fixedImage270Height
        }
        a193: file(relativePath: { eq: "see-our-work/193.jpg" }) {
          ...fixedImage270Height
        }
        a194: file(relativePath: { eq: "see-our-work/194.jpg" }) {
          ...fixedImage270Height
        }
        a195: file(relativePath: { eq: "see-our-work/195.jpg" }) {
          ...fixedImage270Height
        }
        a196: file(relativePath: { eq: "see-our-work/196.jpg" }) {
          ...fixedImage270Height
        }
        a197: file(relativePath: { eq: "see-our-work/197.jpg" }) {
          ...fixedImage270Height
        }
        a198: file(relativePath: { eq: "see-our-work/198.jpg" }) {
          ...fixedImage270Height
        }
        a199: file(relativePath: { eq: "see-our-work/199.jpg" }) {
          ...fixedImage270Height
        }
        a200: file(relativePath: { eq: "see-our-work/200.jpg" }) {
          ...fixedImage270Height
        }
        a201: file(relativePath: { eq: "see-our-work/201.jpg" }) {
          ...fixedImage270Height
        }
        a202: file(relativePath: { eq: "see-our-work/202.jpg" }) {
          ...fixedImage270Height
        }
        a203: file(relativePath: { eq: "see-our-work/203.jpg" }) {
          ...fixedImage270Height
        }
      }
    `}
    render={(data) => <Page {...props} data={data} />}
  />
)

export default SeeOurWorkWithQuery
